import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  BackgroundImages,
  FishermanHeader,
  FadeReveal,
  MarkdownContent,
  OutboundLink,
  InternalLink,
  BasicCarousel,
  componentIterator,
  Image,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  ScrollableContent,
  MultipleComponentIterator,
  FullImageCard,
  TeamMemberV2,
} from "@bluefin/components";
import { Grid, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      bookingUrl,
      allFishermanBusinessLocation,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.modaorganicsalonandspa.com/about/"}
        />
        <PageLayout hero={false} subfooter={false} className={"about-page"}>
          <Section className={"default-hero-section"}>
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              parentClassName={"desktop"}
              carouselProps={{
                leftArrowIcon: "arrow-left",
                rightArrowIcon: "arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <Grid>
                <Grid.Column className={"primary-header"} width={8}>
                  <FishermanHeader
                    as={"h1"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "about_hero_primary_header",
                      defaultValue: "Discover Your True Beauty With Us",
                    })}
                  />
                </Grid.Column>
                <Grid.Column className={"content"} width={8}>
                  <FadeReveal triggerOnce={true}>
                    <div>
                      <FishermanHeader
                        as={"h2"}
                        content={getComponentContentNodeContent({
                          components: fishermanBusinessWebsitePage.components,
                          componentId: "about_hero_secondary_header",
                          defaultValue: "About Us",
                        })}
                      />
                      <FishermanHeader
                        as={"h3"}
                        content={getComponentContentNodeContent({
                          components: fishermanBusinessWebsitePage.components,
                          componentId: "about_hero_tertiary_header",
                          defaultValue: "Unlock Your Natural Radiance",
                        })}
                      />
                      <MarkdownContent
                        content={getComponentContentNodeContent({
                          components: fishermanBusinessWebsitePage.components,
                          componentId: "about_hero_description",
                        })}
                      />
                      <div className={"ctas-container"}>
                        <Button
                          primary={true}
                          as={OutboundLink}
                          to={bookingUrl.url}
                          event={{
                            category: "Booking",
                            action: "Schedule Appointment Intent",
                          }}
                          content={bookingUrl.title}
                        />
                      </div>
                    </div>
                  </FadeReveal>
                </Grid.Column>
              </Grid>
            </BackgroundImages>
            <div className={"mobile"}>
              <FishermanHeader
                as={"h1"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "about_hero_primary_header",
                  defaultValue: "Discover Your True Beauty With Us",
                })}
              />
              <FishermanHeader
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "about_hero_secondary_header",
                  defaultValue: "About Us",
                })}
              />
              <MarkdownContent
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "about_hero_description",
                })}
              />
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  content={bookingUrl.title}
                />
              </div>
              <BackgroundImages
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                carouselProps={{
                  leftArrowIcon: "arrow-left",
                  rightArrowIcon: "arrow-right",
                }}
                imageProps={{ loadStrategy: "eager" }}
              >
                <FishermanHeader
                  as={"h3"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "about_hero_tertiary_header",
                    defaultValue: "Unlock Your Natural Radiance",
                  })}
                />
              </BackgroundImages>
            </div>
          </Section>
          <Section className={"about-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <div>
                    <FishermanHeader
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_content_section_header",
                        defaultValue: "About Us",
                      })}
                    />
                    <FishermanHeader
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_content_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "about_content_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      to={"/services/"}
                      event={{
                        category: "Services",
                        action: "View All Services",
                      }}
                      as={InternalLink}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "content_section_see_all_services_button",
                      })}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <BasicCarousel
                    className={"about-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentIdentifier: "about_content_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"reviews-section"}>
            <Image
              background={true}
              src={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentIdentifier: "reviews_section_background_image",
                numToSelect: 1,
              })}
            >
              <FishermanHeader
                as={"h2"}
                content={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "reviews_section_header",
                  defaultValue: "What Our Clients Say",
                })}
              />
              <BasicCarousel
                size={1}
                displayIndicators={true}
                stepSize={1}
                autoScroll={false}
                leftArrowIcon={"arrow-left"}
                rightArrowIcon={"arrow-right"}
                displayPlayPauseButton={false}
                slide={true}
                items={multipleComponentIterator({
                  iterator: fishermanBusiness.reviews,
                  components: [
                    {
                      component: <ReviewV2 />,
                      propMap: { key: "_id" },
                      children: [
                        {
                          component: <ReviewV2.Text charLimit={440} />,
                          propMap: { text: "text" },
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Rating />,
                              propMap: { rating: "rating" },
                            },
                            {
                              component: <ReviewV2.RatingLabel />,
                              propMap: { rating: "rating" },
                            },
                          ],
                        },
                        {
                          component: <ReviewV2.Line />,
                          children: [
                            {
                              component: <ReviewV2.Author />,
                              propMap: { author: "author" },
                            },
                            {
                              component: <ReviewV2.Source />,
                              propMap: { source: "source", link: "link" },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                })}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
                triggerButtonProps={{ secondary: true }}
                buttonText={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "submit_review_button",
                  defaultValue: "Submit Review",
                })}
              />
            </Image>
          </Section>
          <Section className={"services-section"}>
            <Grid stackable={true}>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={0}>
                  <BasicCarousel
                    className={"services-section-image-carousel"}
                    size={1}
                    slide={true}
                    displayIndicators={false}
                    displayPlayPauseButton={true}
                    autoScroll={false}
                    leftArrowIcon={"arrow-left"}
                    rightArrowIcon={"arrow-right"}
                    mobile={{ size: 1 }}
                    items={componentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentIdentifier: "services_section_images",
                      }),
                      component: (
                        <Image
                          background={true}
                          className={"single-item-carousel-image"}
                        />
                      ),
                      propMap: { src: "__all__" },
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={8}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <div>
                    <FishermanHeader
                      as={"h2"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_header",
                        defaultValue: "Services",
                      })}
                    />
                    <FishermanHeader
                      as={"h3"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_subheader",
                      })}
                    />
                    <MarkdownContent
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_description",
                      })}
                    />
                    <Button
                      primary={true}
                      to={"/services/"}
                      event={{
                        category: "Services",
                        action: "View All Services",
                      }}
                      as={InternalLink}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "services_section_see_all_services_button",
                      })}
                    />
                  </div>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </Section>
          <Section className={"team-section"}>
            <ScrollableContent
              direction={"horizontal"}
              distanceFactor={"snap"}
              intervalFactor={500}
            >
              <Grid stackable={true} columns={2}>
                <Grid.Column className={"content-column"} width={4}>
                  <FishermanHeader
                    as={"h2"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_header",
                      defaultValue: "Our Team",
                    })}
                  />
                  <FishermanHeader
                    as={"h3"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_subheader",
                      defaultValue: "Boost Your True Beauty",
                    })}
                  />
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "staff_section_description",
                    })}
                  />
                  <Button
                    as={InternalLink}
                    to={"/team/"}
                    primary={true}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "see_all_team_button",
                      defaultValue: "See Our Team",
                    })}
                    className={"see-more-button"}
                    event={{
                      category: "Team",
                      action: "View All Team Members",
                    }}
                  />
                  <div className={"triggers-container"}>
                    <ScrollableContent.PreviousTrigger />
                    <ScrollableContent.NextTrigger />
                  </div>
                </Grid.Column>
                <Grid.Column className={"team-member-cards-column"} width={12}>
                  <ScrollableContent.Content hideScrollbar={true}>
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessTeam.nodes}
                      components={[
                        {
                          component: (
                            <FadeReveal
                              className={"ui card"}
                              triggerOnce={true}
                            />
                          ),
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <FullImageCard
                                  link={true}
                                  className={"team-member-card"}
                                  as={InternalLink}
                                  event={{
                                    category: "Team",
                                    action: "View Team Member",
                                  }}
                                />
                              ),
                              propMap: {
                                key: "_id",
                                image: "gatsbyImage",
                                to: createTeamMemberSlug,
                              },
                              children: [
                                {
                                  component: <TeamMemberV2.Name />,
                                  propMap: { name: "name" },
                                },
                                {
                                  component: <TeamMemberV2.Role />,
                                  propMap: { role: "role" },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </ScrollableContent.Content>
                  <div className={"actions-container"}>
                    <Button
                      as={InternalLink}
                      to={"/team/"}
                      content={getComponentContentNodeContent({
                        components: fishermanBusinessWebsitePage.components,
                        componentId: "see_all_team_button",
                        defaultValue: "See Our Team",
                      })}
                      className={"see-more-button"}
                      primary={true}
                    />
                    <div className={"triggers-container"}>
                      <ScrollableContent.PreviousTrigger />
                      <ScrollableContent.NextTrigger />
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            </ScrollableContent>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
    }
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
